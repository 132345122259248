import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import base_url from "./../../api/bootapi";
import Nav from "./../NavBar/Nav";
import Footer from "../Footer/Footer";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, CardBody, CardText, Col, Row, Button, Table } from "reactstrap";
import Checkbox from "@mui/material/Checkbox";

toast.configure();

export default function Home() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [country1, setcountry] = useState("United States");
  const [country2, setcountry2] = useState("United States");
  const [countryStatus, setCountryStatus] = useState(false);
  const [countryStatus2, setCountryStatus2] = useState(false);
  const [region, setregion] = useState();
  const [regionStatus, setRegionStatus] = useState(false);
  // const [isOpen, setIsOpen] = React.useState(false);

  const selectCountry = (val) => {
    console.log(val);
    setcountry(val);
  };

  const selectRegion = (val) => {
    console.log(val);
    setregion(val);
  };

  const selectCountry2 = (val) => {
    console.log(val);
    setcountry2(val);
  };

  const onSubmit = (data2) => {
    if (!country1) {
      setCountryStatus(true);
      return;
    }

    if (!region) {
      setRegionStatus(true);
      return;
    }
    if (!country2) {
      setCountryStatus2(true);
      return;
    }
    let first_name = data2.first_name;
    let middle_name = data2.middle_name;
    let last_name = data2.last_name;
    let email_id = data2.email_id;
    let phone = data2.phone;
    let nationality = country1;
    let state = region;
    let place_of_born = country2;
    let date_of_birth = data2.date_of_birth;
    let passport_number = data2.passport_number;
    let passport_expiry_date = data2.passport_expiry_date;
    let gender = data2.gender;
    let status = 1;

    let data = {
      first_name,
      middle_name,
      last_name,
      email_id,
      phone,
      nationality,
      state,
      place_of_born,
      date_of_birth,
      passport_number,
      passport_expiry_date,
      gender,
      status,
    };
    // console.log(data);
    // setbackdropopen(true);
    postUser(data);
  };

  const postUser = (data) => {
    axios.post(`${base_url.api1}/passport/passport_create_user`, data).then(
      (response) => {
        // toast.success("Success");
        // Swal.fire('Thank You For Contacting Us ')
        Swal.fire({
          position: "center",
          width: "62rem",
          icon: "success",
          title: "Thank You For Trusting Us with Plan Ahead",
          showConfirmButton: false,
          timer: 1500,
        });
        document.getElementById("myForm").reset();
      },
      (error) => {
        // toast.error("User Not Created!");
        Swal.fire({
          position: "center",
          width: "62rem",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
        });
      }
    );
  };

  const onSubmitForm = (data2) => {
    let first_name = data2.first_name;
    let last_name = data2.last_name;
    let email_id = data2.email_id;
    let where_to = selectedTripType;
    let reason = data2.reason;
    let message = data2.message;

    let institutionTypearray = [];
    let arr = Object.keys(checkboxstate);
    for (let i = 0; i < arr.length; i++) {
      let key = arr[i];
      if (checkboxstate[key]) {
        institutionTypearray.push(key);
      }
    }
    let business = 0;
    let spiritual = 0;
    let liesure = 0;
    for (let i = 0; i < institutionTypearray.length; i++) {
      if (institutionTypearray[i] === "business") {
        business = 1;
      } else if (institutionTypearray[i] === "spiritual") {
        spiritual = 1;
      } else if (institutionTypearray[i] === "liesure") {
        liesure = 1;
      }
    }

    let data = {
      first_name,
      last_name,
      email_id,
      where_to,
      // reason,
      business,
      spiritual,
      liesure,
      message,
    };
    console.log(data);
    // setbackdropopen(true);
    postUserForm(data);
  };

  const postUserForm = (data) => {
    axios.post(`${base_url.api1}/passport/tour_planning`, data).then(
      (response) => {
        // toast.success("Success");
        // Swal.fire('Thank You For Contacting Us ')
        Swal.fire({
          position: "center",
          width: "62rem",
          icon: "success",
          title: "Thank You For Trusting Us with Plan Ahead",
          showConfirmButton: false,
          timer: 1500,
        });
        document.getElementById("myForm").reset();
        resetState(); // Reset state after successful form submission
      },
      (error) => {
        // toast.error("User Not Created!");
        Swal.fire({
          position: "center",
          width: "62rem",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
        });
      }
    );
  };

  const [checked, setChecked] = React.useState(true);
  const [checked2, setChecked2] = React.useState(true);
  const [checked3, setChecked3] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChange2 = (event) => {
    setChecked2(event.target.checked);
  };

  const handleChange3 = (event) => {
    setChecked3(event.target.checked);
  };

  const initialState = {
    business: false,
    spiritual: false,
    liesure: false,
  };

  const [checkboxstate, setState] = React.useState(initialState);
  const checkboxchange = (event) => {
    setState({ ...checkboxstate, [event.target.name]: event.target.checked });
  };

  const resetState = () => {
    setState(initialState);
  };

  const [selectedTripType, setTripType] = useState("International");
  const handleTripType = (event) => {
    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index];
    const selectedTripName = optionElement.getAttribute("value");
    setTripType(selectedTripName);
    console.log(selectedTripName);
  };

  return (
    <React.Fragment>
      <Nav />
      <div className="banner">
        <div className="about">
          <div className="maincon">
            <div id="booking" className="section">
              <div className="section-center">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="booking-cta">
                        <h1>WELCOME!</h1>
                        <p>
                          PLANING IS WHAT WE DO BEST, LET OUR WONDERFUL TEAM
                          HELP PLAN YOUR AMAZING JOURNY.
                        </p>

                        <p>
                          We will help you plan, arrange, book, and confirm
                          whatever journey you are looking for, BUSINESS,
                          SPIRITUAL, LIESURE
                        </p>

                        <p>
                          We will make sure you are satisfied and assist you
                          throughout your journey from the beginning of planning
                          until you arrive safely back home, ACCOMPLISHED,
                          INSPIRED, REJUVENATED
                        </p>

                        <p>CONTACT US TO PLAN AHEAD!</p>

                        <p>
                          <a href="mailto:moshe@planaheadvip.com">
                            moshe@planaheadvip.com
                          </a>
                          <br />
                          +1 845 537 9661
                        </p>
                      </div>
                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-md-5">
                      <div className="booking-form">
                        <form id="myForm" onSubmit={handleSubmit(onSubmitForm)}>
                          <div class="container">
                            {/* <br />  <p class="text-center">More bootstrap 4 components on <a href="http://bootstrap-ecommerce.com/"> Bootstrap-ecommerce.com</a></p>
                            <hr /> */}

                            <div class="row justify-content-center">
                              <div>
                                <article>
                                  <div class="form-row">
                                    <div class="form-group col-md-6">
                                      <label>Name </label>
                                      <input
                                        class="form-control"
                                        placeholder=""
                                        type="text"
                                        {...register("first_name", {
                                          required: true,
                                        })}
                                      />
                                      <label className="label-small">
                                        First
                                      </label>
                                      {errors.first_name && (
                                        <p className="errormessage">
                                          First Name is Required
                                        </p>
                                      )}
                                    </div>
                                    <div class="form-group col-md-6">
                                      <label>&nbsp;</label>
                                      <input
                                        class="form-control"
                                        placeholder=" "
                                        type="text"
                                        {...register("last_name", {
                                          required: true,
                                        })}
                                      />
                                      <label className="label-small">
                                        Last
                                      </label>
                                      {errors.last_name && (
                                        <p className="errormessage">
                                          Last Name is Required
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div class="form-row">
                                    <div class="form-group col-md-12">
                                      <br />
                                      <label>Email address</label>
                                      <input
                                        class="form-control"
                                        placeholder=""
                                        type="email"
                                        className="form-control underline-input"
                                        {...register("email_id", {
                                          required: true,
                                          pattern:
                                            /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                                        })}
                                      />
                                      {errors.email_id && (
                                        <p className="errormessage">
                                          Email is Required
                                        </p>
                                      )}
                                      <small class="form-text text-muted">
                                        We'll never share your email with anyone
                                        else.
                                      </small>
                                    </div>
                                  </div>
                                  <div class="form-row">
                                    <div class="form-group col-md-12">
                                      <br />
                                      <br />
                                      <label>
                                        Where are you planning of traveling to?
                                      </label>
                                      <br />
                                      <br />
                                      <select
                                        className="form-control"
                                        name="where_to"
                                        onChange={handleTripType}
                                        style={{ backgroundColor: "#f5f5f9" }}
                                      >
                                        <option value="International">
                                          International
                                        </option>
                                        <option value="Domestic">
                                          Domestic
                                        </option>
                                        <option value="Not sure yet">
                                          Not sure yet
                                        </option>
                                      </select>
                                      <br />
                                      <br />
                                      <label class="form-check form-check-inline">
                                        <Checkbox
                                          checked={checkboxstate.business}
                                          onChange={checkboxchange}
                                          inputProps={{
                                            "aria-label": "primary checkbox",
                                          }}
                                          id="flexCheckDefault"
                                          name="business"
                                        />
                                        <span class="form-check-label">
                                          {" "}
                                          BUSINESS{" "}
                                        </span>
                                      </label>
                                      <label class="form-check form-check-inline">
                                        <Checkbox
                                          checked={checkboxstate.spiritual}
                                          onChange={checkboxchange}
                                          inputProps={{
                                            "aria-label": "primary checkbox",
                                          }}
                                          id="flexCheckDefaultone"
                                          name="spiritual"
                                        />
                                        <span class="form-check-label">
                                          {" "}
                                          SPIRITUAL
                                        </span>
                                      </label>
                                      <label class="form-check form-check-inline">
                                        <Checkbox
                                          checked={checkboxstate.liesure}
                                          onChange={checkboxchange}
                                          inputProps={{
                                            "aria-label": "primary checkbox",
                                          }}
                                          id="flexCheckDefaulttwo"
                                          name="liesure"
                                        />
                                        <span class="form-check-label">
                                          {" "}
                                          LIESURE{" "}
                                        </span>
                                      </label>
                                    </div>
                                  </div>
                                  <br />
                                  <div class="form-row">
                                    <div class="form-group col-md-12">
                                      <br />
                                      <label>Comment or Message</label>
                                      <textarea
                                        style={{
                                          width: "100%",
                                          height: "90px",
                                          backgroundColor: "#f5f5f9",
                                          padding: "10px",
                                        }}
                                        type="text"
                                        {...register("message", {
                                          required: true,
                                        })}
                                      />
                                      {errors.message && (
                                        <p className="errormessage">
                                          Message is Required
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div class="form-row">
                                    <div class="form-group col-md-12">
                                      <button
                                        type="submit"
                                        class="btn btn-primary btn-block"
                                      >
                                        {" "}
                                        Submit{" "}
                                      </button>
                                    </div>
                                  </div>
                                </article>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
