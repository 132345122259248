import "./App.css";
import React from "react";
import Home from "./components/Home/Home";
import Contact from "./components/Contact/Contact";
import Resource from "./components/Resource/Resource";
import MilePoints from "./components/MilePoints/MilePoints";
import TermsConditions from "./components/TermsConditions/TermsConditions";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/resource" element={<Resource />} />
      <Route path="/terms" element={<TermsConditions />} />
      <Route path="/points" element={<MilePoints />} />
    </Routes>
  );
}

export default App;
