import React, { useState, useEffect, useRef } from "react";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa';




export default function Footer() {
  return (
    <React.Fragment>
      <div className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col ">
              <div className="footer-main-col">
                <FaMapMarkerAlt size={30} /><br />
                2 Sunrise Drive # 202 Monsey NY 10952
              </div>
            </div>
            <div className="col">
              <div className="footer-main-col">
                <FaEnvelope size={30} /><br />
                moshe@planaheadvip.com<br /><br />
              </div>
            </div>
            <div className="col">
              <div className="footer-main-col">
                <FaPhoneAlt size={30} /><br />
                845-537-9661<br /><br />
              </div>
            </div>
            <div className="col footer-copyright">
              Copyright &copy; Plan Ahead VIP Inc. All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
