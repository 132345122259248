// import React from 'react';
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import base_url from "./../../api/bootapi";
import Nav from "./../NavBar/Nav";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
toast.configure();

export default function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data2) => {
    let full_name = data2.full_name;
    let middle_name = data2.middle_name;
    let last_name = data2.last_name;
    let email = data2.email;
    let phone = data2.phone;
    let adress = data2.adress;
    let message = data2.message;
    let status = 1;

    let data = {
      full_name,
      middle_name,
      last_name,
      email,
      phone,
      adress,
      message,
      status,
    };
    // console.log(data);
    // setbackdropopen(true);
    postUser(data);
  };

  const postUser = (data) => {
    axios.post(`${base_url.api1}/passport/contact_us`, data).then(
      (response) => {
        // toast.success("Success");
        // Swal.fire('Thank You For Contacting Us ')
        Swal.fire({
          position: "center",
          width: "62rem",
          icon: "success",
          title: "Thank You For Contacting",
          showConfirmButton: false,
          timer: 1500,
        });
        document.getElementById("myForm").reset();
      },
      (error) => {
        // toast.error("User Not Created!");
        Swal.fire({
          position: "center",
          width: "62rem",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
        });
      }
    );
  };

  return (
    <React.Fragment>
      <Nav />
      <div className="banner">
        <div className="about">
          <div className="maincon">
            <div id="booking" className="section">
              <div className="section-center">
                <div className="container">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-md-7">
                      <div className="booking-cta">
                        <h1>
                          {" "}
                          It's Never Too Early to Start Planning Your Next
                          Adventure
                        </h1>
                        <h2 style={{ marginTop: 30 }}>
                          Let us PLAN AHEAD for you
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="opc"
                        style={{
                          backgroundcolor: "rgb(255, 255, 255)",
                          opacity: "1",
                          color: "rgb(255, 255, 255)",
                        }}
                      >
                        <div className="booking-form">
                          <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                              {/* <div className="col-sm-6"> */}
                              <div className="form-group">
                                <span className="form-label">Full Name</span>
                                <input
                                  type="text"
                                  className="form-control underline-input"
                                  {...register("full_name", { required: true })}
                                />
                                {errors.full_name && (
                                  <p className="errormessage">
                                    Name is Required
                                  </p>
                                )}
                              </div>
                              {/* </div> */}

                              {/* <div className="col-sm-6">
                                                            <div className="form-group">
                                                                <span className="form-label">Middle Name</span>
                                                                <input type="text"  className="form-control underline-input"
                                                                {...register("middle_name", { required: true })}  />
                                                                 {errors.middle_name && (<p className="errormessage">Name is Required</p>)}
                                                            </div>
                                                        </div> */}
                            </div>

                            {/* <div className="form-group">
                                                        <span className="form-label">Last Name</span>
                                                        <input type="text"  className="form-control underline-input"
                                                                {...register("last_name", { required: true })}  />
                                                                 {errors.last_name && (<p className="errormessage">Name is Required</p>)}
                                                    </div> */}

                            {/* <div className="form-group">
                                                        <span className="form-label">Address</span>
                                                        <input className="form-control" type="text"
                                                            placeholder="Your Address"/>
                                                    </div> */}

                            <div className="form-group">
                              <span className="form-label">Phone Number</span>
                              <input
                                type="number"
                                className="form-control underline-input"
                                {...register("phone", { required: true })}
                              />
                              {errors.phone && (
                                <p className="errormessage">
                                  Phone is Required
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <span className="form-label">Email Address</span>
                              <input
                                type="email"
                                className="form-control underline-input"
                                {...register("email", {
                                  required: true,
                                  pattern:
                                    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$/,
                                })}
                              />
                              {errors.email && (
                                <p className="errormessage">
                                  Email is Required
                                </p>
                              )}
                            </div>

                            <div className="form-group">
                              <span className="form-label">Message</span>
                              <textarea
                                type="text"
                                rows="4"
                                className="form-control underline-input"
                                {...register("message", { required: true })}
                              />
                              {errors.message && (
                                <p className="errormessage">
                                  Message is Required
                                </p>
                              )}
                            </div>

                            {/* <div className="row">


                                                    </div> */}
                            <div className="form-btn" style={{ opacity: "1" }}>
                              <button className="submit-btn">Submit</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
