import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import base_url from "./../../api/bootapi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

toast.configure();

export default function Nav() {
  return (
    <React.Fragment>
      <div className="container">
        <nav className="navbar">
          <div className="logo">
            <img src="assets/image/plan-logo-2.png" alt="" className="src" />
          </div>
          <ul className="nav-links">
            <input type="checkbox" id="checkbox_toggle" />
            <label htmlFor="checkbox_toggle" className="hamburger">
              &#9776;
            </label>
            <div className="menu">
              <Link to="/">
                <li>
                  <a href="/">Home</a>
                </li>
              </Link>
              <Link to="/">
                {" "}
                <li>
                  <a href="/">About Us</a>
                </li>
              </Link>
              <Link to="/points">
                <li href="/"> Miles/Points</li>
              </Link>
              <Link to="/resource">
                {" "}
                <li href="/">Passport Resources </li>{" "}
              </Link>
              <Link to="/terms">
                {" "}
                <li href="/">Terms and Conditions </li>{" "}
              </Link>
              <Link to="/contact">
                {" "}
                <li href="/">Contact Us</li>
              </Link>
            </div>
          </ul>
        </nav>
      </div>
    </React.Fragment>
  );
}
