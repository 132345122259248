import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Nav from "./../NavBar/Nav";

export default function Resource() {
  return (
    <React.Fragment>
      <Nav />

      <div className="title-banner">
        <div className="container">
          <div className="row">
            <h1>Terms and Conditions</h1>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 mt-5 mb-3">
        <div className="row">
          <div className="col">
            <div className="terms-content-main">
              <h2>IMPORTANT</h2>
              <p>
                To ensure an enjoyable travel experience please read our terms
                and conditions prior to making a booking with us. These terms
                and conditions form an agreement between you and Superior Travel
                and govern the relationship between us. Before we proceed with
                any booking, we will need you to confirm you have read and
                understood these terms and conditions either by written or
                electronic acceptance.
              </p>
            </div>

            <div className="terms-content-main">
              <h2>1. PAYMENT</h2>
              <p>
                Payment of our fees is required at the time the services are
                provided.
              </p>
            </div>

            <div className="terms-content-main">
              <h2>2. TRAVEL INSURANCE</h2>
              <p>
                It is highly recommended that you have comprehensive travel
                insurance for the full duration of your trip which is arranged
                before paying the first deposit to cover any unforeseen
                cancellations prior to travel commencing. We can assist you in
                obtaining travel insurance.
              </p>
            </div>

            <div className="terms-content-main">
              <h2>3. TRAVEL DOCUMENTATION</h2>
              <p>
                You are required to be familiar with the appropriate travel
                documents required for your trip including passport, visa and
                health requirements. You are responsible for all entry, exit,
                health laws, regulations, orders, demands or requirements of
                countries visited or transited. Your passport and visa
                requirements are your responsibility. Please be aware that
                passengers are not generally permitted to enter another country
                without 6 months passport validity. It is your responsibility to
                check all documentation we provide you in a timely manner and to
                notify us of any errors to allow us sufficient time to make any
                necessary corrections{" "}
              </p>
            </div>

            <div className="terms-content-main">
              <h2>4. RESPONSIBILITY</h2>
              <p>
                Plan ahead VIP Inc. does not own, manage, control or operate any
                transportation, vehicle, accommodation or tours. In our capacity
                as agent arranging bookings on behalf of suppliers, we rely on
                them to accurately describe their services whether visually or
                in writing. Whilst we endeavor to validate the accuracy of their
                descriptions, we cannot be held accountable for variations or
                guarantee the performance of our suppliers. If you think, the
                supplier is falsely representing a service we will work to
                resolve it directly with the supplier. Plan ahead VIP Inc.
                excludes all liability for loss or damage that may arise from
                the actions of third-party agents or suppliers. We do not accept
                liability for any claim for compensation for any damage, loss,
                injury, delays, cancellation and additional expenses or
                inconvenience caused by any events, which are outside the
                control of Plan Ahead VIP Inc.
              </p>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
