import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Nav from "./../NavBar/Nav";

export default function Resource() {
  return (
    <React.Fragment>
      <Nav />

      <div className="title-banner">
        <div className="container">
          <div className="row">
            <h1>Miles/Points</h1>
          </div>
        </div>
      </div>

      <div className="container pt-5 pb-5 mt-5 mb-3">
        <div className="row">
          <div className="col">
            <div className="terms-content-main">
              <h2>
                Have You Accumulated Airlines Miles Or Credit Card Rewards?
              </h2>
              <p>
                Let us help you plan your perfect trip with you Miles/Rewards,
                our team of experts would work with you to make your trip happen
                hassle free and almost expense free (excluding the mandatory
                taxes and fees and our flat rate) so you can get the reward you
                rightfully earned.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="terms-content-main">
              <h2>Here is a partial list of Mils/Rewards we will work with</h2>

              <div className="row">
                <div className="col-md-4">
                  <ul>
                    <li>~ AIRLINES</li>
                    <li>~ UNITED AIRLINES</li>
                    <li>~ DELTA AIRLINES</li>
                    <li>~ JET BLUE AIRLINES</li>
                    <li>~ MILES AND MORE (LUFTHANSA/AUSTRIAN/SWISS/LOT)</li>
                    <li>~ TURKISH AIRLINES</li>
                    <li>~ BRITISH AIRWAYS</li>
                  </ul>
                </div>

                <div className="col-md-4">
                  <ul>
                    <li>~ VIRGIN ATLANTIC</li>
                    <li>~ FLYING BLUE (AIR FRANCE/KLM)</li>
                    <li>~ HOTELS</li>
                    <li>~ MARRIOTT</li>
                    <li>~ HILTON</li>
                    <li>~ IHG</li>
                  </ul>
                </div>

                <div className="col-md-4">
                  <ul>
                    <li>~ CHOICE HOTELS</li>
                    <li>~ REWARDS PROGRAMS</li>
                    <li>~ AMERICAN EXPRESS</li>
                    <li>~ CHASE ULTIMATE REWARDS</li>
                    <li>~ CITI THANKYOU POINTS</li>
                    <li>~ CHOICE HOTELS</li>
                  </ul>
                </div>
              </div>

              <ul></ul>
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}
